import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import topLeft from "../images/4.0 About/top-left.svg";
import topRight from "../images/4.0 About/top-right.svg";
import bottomLeft from "../images/4.0 About/bottom-left.svg";
import bottomRight from "../images/4.0 About/bottom-right.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="About | Nikkael Home Loans | Tustin Mortgage Broker"
        description="At Nikkael Home Loans you'll benefit from our combined 90 years of experience and extensive, specialized knowledge. Learn more about us here."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>An Attorney-Owned Orange County Mortgage Broker</h1>
              <p>
                At Nikkael Home Loans, we have a higher level of ethics and
                accountability than you might find elsewhere because we're
                attorney-owned and operated. You'll benefit from our combined 60
                years of experience and extensive, specialized knowledge in
                mortgage law, loan origination, and home protection.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/4.0 About/1.0 about.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="relative mb-20 bg-secondary-500 pt-32 pb-32 md:mb-32 md:pt-40 md:pb-48">
        <div className="absolute left-4 top-4 md:left-6 md:top-6">
          <img src={topLeft} />
        </div>
        <div className="absolute right-4 top-4 md:right-6 md:top-6">
          <img src={topRight} />
        </div>
        <div className="absolute left-4 bottom-4 md:left-6 md:bottom-6">
          <img src={bottomLeft} />
        </div>
        <div className="absolute right-4 bottom-4 md:right-6 md:bottom-6">
          <img src={bottomRight} />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[880px] text-center">
            <h2 className="text-white">Our Mission / Philosophy</h2>
            <p className="mb-0 text-xl text-white/80">
              Everyone’s situation is different and unique, so we serve the
              individual to find the right loan. We’ve provided happy clients
              with their perfect loan by understanding exactly what they need
              and working closely with them to find the best solution, even if
              it’s a complex one.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-10 md:mb-12">
            <h2>Meet The Team</h2>
          </header>
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-10">
            {data.teamMembers.edges.map(({ node }, i) => {
              return (
                node.headshot && (
                  <Link
                    to={`/${node.slug.current}/`}
                    className="group block font-normal text-typography-body no-underline hover:text-typography-body"
                    key={i}
                  >
                    <div className="mb-6 overflow-hidden">
                      <GatsbyImage
                        image={node.headshot.asset.gatsbyImageData}
                        loading="lazy"
                        className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div>
                      <div className="heading-four mb-2">{node.name}</div>
                      <div className="text-sm font-bold uppercase tracking-wide">
                        {node.title}
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
          </div>
        </div>
      </section>

      <ValueProps />
      <Testimonials />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-home.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-home.jpg" }
    ) {
      publicURL
    }
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
          title
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 746)
            }
          }
        }
      }
    }
  }
`;

export default Page;
