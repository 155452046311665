import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValueProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Attorney owned company..svg"
        }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Friendly, attentive team..svg"
        }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/No loan is too complex..svg"
        }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon3.publicURL,
      heading: "No situation is too complex",
      text: "With a real estate attorney at the helm, there is no situation that is too unique or complex for us to handle. Our highly trained team specializes in complex loans.",
    },
    {
      icon: data.icon1.publicURL,
      heading: "We’re also real estate attorneys",
      text: "Nikkael Home Loans is owned and operated by attorneys who know how to work through challenging matters to find the best solution for you.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "There’s no pressure",
      text: "We won’t sell you on a loan—instead, we will educate and inform you about the best options to match your needs.",
    },
  ];

  return (
    <section className={`${className || "my-20 md:mb-32 md:px-5"}`}>
      <div className="container">
        <header className=" mb-10 text-left md:mb-14 lg:w-[66%]">
          <HeadingTag>
            The Nikkael Advantage: Attorney Owned and Operated
          </HeadingTag>
          <p>
            As an attorney-owned and operated mortgage company, we owe our
            clients a higher ethical obligation than a traditional mortgage
            broker. That means our clients have a better experience, with
            superior service, and an outcome that meets their needs, not ours.
          </p>
        </header>

        <div className="grid gap-y-8 text-left md:grid-cols-3 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mb-3"
                />
                <div>
                  <p className="heading-six mb-3">{content.heading}</p>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
